import { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { IPerformance, IPerformanceSubmission, ILesson, ISchool } from "../types";
import formatDate from "../utils/formatDate";
import { Controller, useForm } from "react-hook-form";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface IFormInputs {
  performance: IPerformance;
  lesson: PerformableLesson;
  repertoire: string;
  accompanist: boolean;
}

interface PerformableLesson extends ILesson {
  performance_id: number,
  description: string,
  school: ISchool,
  lessonSubject: {
    id: number,
    subject: string,
    lessonCategory: {
      id: number
    }
  },
  performanceSubmissions: IPerformanceSubmission[];
}

function Performance({ performances }: { performances: IPerformance[] }) {
  const [lessons, setLessons] = useState<PerformableLesson[]>([]);
  const [isRemoveButtonAvailable, setIsRemoveButtonAvailable] = useState<boolean>(false);
  const [isPerformanceInputAvailable, setIsPerformanceInputAvailable] = useState<boolean>(false);
  const [isRemoveSubmitting, setIsRemoveSubmitting] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    resetField,
    watch,
    getValues,
    reset,
    control,
    setValue,
    formState: { isSubmitting, isDirty, isValid, errors },
  } = useForm<IFormInputs>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      performance: {},
      lesson: {},
      repertoire: "",
      accompanist: false,
    },
  });

  const selectedPerformance = watch("performance");
  const selectedLesson = watch("lesson");

  useEffect(() => {
    isInputAvailable();
    isRemoveAvailable();
  }, [selectedLesson]);

  // get lessons eligible to participate in the currently selected performance
  useEffect(() => {
    if (selectedPerformance && selectedPerformance.performance_id) {
      fetch(`/api/performances/lessons/${selectedPerformance.performance_id}`)
        .then((res) => res.json())
        .then((data) => {
          // reset values for previous selection and update the lesson list
          resetField("lesson");
          resetField("repertoire");
          resetField("accompanist");
          setLessons(data);
        })
        .catch((e) => console.error(e));
    }
  }, [selectedPerformance]);

  // get previously submitted repertoire for the selected lesson
  useEffect(() => {
    if (selectedPerformance && selectedPerformance.performance_id) {
      if (selectedLesson && selectedLesson.id) {
        const performanceID = selectedPerformance.performance_id;
        const lessonCategoryID = selectedLesson.lessonSubject.lessonCategory.id;
        const lessonID = selectedLesson.id;

        fetch(
          `/api/performances/repertoire/${performanceID}/${lessonCategoryID}/${lessonID}`
        )
          .then((res) => res.json())
          .then((existingPerformanceSubmission: IPerformanceSubmission) => {
            if (existingPerformanceSubmission) {
              if (existingPerformanceSubmission?.repertoire) {
                setValue("repertoire", existingPerformanceSubmission.repertoire, {
                  shouldValidate: true
                });
              }
              if (existingPerformanceSubmission?.accompanist) {
                setValue("accompanist", existingPerformanceSubmission.accompanist, {
                  shouldValidate: true
                });
              }
            }
          })
          .catch(() => {
            // reset repertoire and accompanist fields if no PerformanceSubmission found
            resetField("repertoire");
            resetField("accompanist");
          });
      }
    }
  }, [selectedLesson]);

  async function onSubmit(formData: IFormInputs) {
    const performanceID = formData?.performance?.performance_id;
    const lessonID = formData?.lesson.id;
    const lessonCategoryID = formData?.lesson.lessonSubject.lessonCategory.id;

    if (performanceID && lessonID) {
      await fetch(`/api/performances/submit`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          performance_id: performanceID,
          lesson_id: lessonID,
          lesson_category_id: lessonCategoryID,
          repertoire: formData?.repertoire,
          accompanist: formData?.accompanist,
        }),
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(res.statusText);
          }
        })
        .then(() => {
          // reset the form fields except for the selected performance
          const selectedPerformance = formData.performance;
          reset({
            performance: selectedPerformance,
            lesson: {},
            repertoire: "",
            accompanist: false,
          });
        })
        .catch((e) => console.error(e));
    }
  }

  async function onRemove() {
    const lesson = getValues("lesson")
    const lessonID = lesson?.id;
    const lessonCategoryID = lesson?.lessonSubject?.lessonCategory?.id;
    const performanceSubmission = lesson?.performanceSubmissions;

    if (!lessonID || !lessonCategoryID || !performanceSubmission || !performanceSubmission.length) {
      return;
    }

    setIsRemoveSubmitting(true);
    await fetch(`/api/performances/remove`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        performance_submission_id: performanceSubmission[0].id,
        lesson_id: lessonID,
        lesson_category_id: lessonCategoryID
      })
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        // reset all form fields except for the selected performance
        const selectedPerformance = getValues("performance");
        reset({
          performance: selectedPerformance,
          lesson: {},
          repertoire: "",
          accompanist: false,
        });
      })
      .catch((e) => console.error(e.message))
      .finally(() => setIsRemoveSubmitting(false));
  }

  function validateAccompanistField(accompanist: boolean, formData: IFormInputs) {
    // No PerformanceSubmission exists. Falsy repertoire and accompanist
    // values are valid submission.
    const currentPerformanceSubmission = selectedLesson?.performanceSubmissions;
    if (!currentPerformanceSubmission || !currentPerformanceSubmission.length) {
      return true;
    }

    // A PerformanceSubmission exists for the selected lesson.
    const {
      repertoire: existingRepertoire,
      accompanist: existingAccompanist
    } = currentPerformanceSubmission[0];

    // An update to the PerformanceSubmission requires updated repertoire
    // and accompanist values. NOTE: Empty string are stored as NULL in the
    // database so coalesced to "".
    if (
      accompanist === existingAccompanist &&
      formData.repertoire === (existingRepertoire ?? "")
    ) {
      return false;
    } else {
      return true;
    }
  }

  function validateRepertoireField(repertoire: string, formData: IFormInputs) {
    // No PerformanceSubmission exists. Falsy repertoire and accompanist
    // values are valid submission.
    const currentPerformanceSubmission = selectedLesson?.performanceSubmissions;
    if (!currentPerformanceSubmission || !currentPerformanceSubmission.length) {
      return true;
    }

    // A PerformanceSubmission exists for the selected lesson.
    const {
      repertoire: existingRepertoire,
      accompanist: existingAccompanist
    } = currentPerformanceSubmission[0];

    // An update to the PerformanceSubmission requires updated repertoire
    // and accompanist values. NOTE: Empty string are stored as NULL in the
    // database so coalesced to "".
    if (
      formData.accompanist === existingAccompanist &&
      repertoire === (existingRepertoire ?? "")
    ) {
      return false;
    } else {
      return true;
    }
  }

  function validateLessonField(lesson: PerformableLesson) {
    if (!lesson || !lesson.id) {
      return false;
    }
    return true;
  }

  function submissionDeadlinePassed() {
    return new Date(selectedPerformance.submission_deadline).getTime() < Date.now();
  }

  // Remove PerformanceSubmission should only be available if the selected
  // lesson has an existing PerformanceSubmission.
  function isRemoveAvailable() {
    if (!submissionDeadlinePassed() && selectedLesson.performanceSubmissions?.length) {
      return setIsRemoveButtonAvailable(true);
    }
    return setIsRemoveButtonAvailable(false);
  }

  // Disable repertoire and accompanist input if there isn't a currently
  // selected lesson. The values are reset on lesson selection anyway
  function isInputAvailable() {
    if (!submissionDeadlinePassed() && selectedLesson?.id) {
      return setIsPerformanceInputAvailable(true);
    }
    return setIsPerformanceInputAvailable(false)
  }

  return (
    <>
      <div className="max-w-xl m-auto">
        <div className="bg-white py-5 sm:px-6">
          <h3 className="text-xl font-semibold text-gray-900 mb-4">
            Submit performer
          </h3>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-4">
              <Controller
                name="performance"
                rules={{ required: true }}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <SelectPerformance
                    value={value}
                    onChange={onChange}
                    options={performances}
                  />
                )}
              />
            </div>

            <PerformanceInformation performance={selectedPerformance} />

            <div className="mt-4">
              <Controller
                name="lesson"
                rules={{
                  required: true,
                  validate: validateLessonField
                }}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <SelectLesson
                    value={value}
                    onChange={onChange}
                    options={lessons}
                  />
                )}
              />
            </div>

            <div className="mt-4">
              <label
                htmlFor="repertoire"
                className="block text-sm font-medium text-gray-700"
              >
                Repertoire
              </label>
              <div className="mt-1">
                <input
                  {...register("repertoire", {
                    required: false,
                    validate: validateRepertoireField
                  })}
                  type="text"
                  id="repertoire"
                  disabled={!isPerformanceInputAvailable}
                  className={
                    classNames(
                      !isPerformanceInputAvailable ? "bg-gray-200" : "",
                      "block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm"
                    )
                  }
                  aria-describedby="repertoire-description"
                  placeholder="TBC"
                />
              </div>
              <p
                className="mt-2 text-sm text-gray-500"
                id="repertoire-description"
              >
                Repertoire can be modified until the submission deadline when it
                will be sent to customers. You will be CC'd to confirm the
                repertoire if it is not specified.
              </p>
            </div>

            <fieldset className="space-y-4">
              <legend className="sr-only">Requires accompanist</legend>
              <div className="relative flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    id="accompanist"
                    aria-describedby="accompanist-description"
                    {...register("accompanist", {
                      required: false,
                      validate: validateAccompanistField
                    })}
                    type="checkbox"
                    disabled={!isPerformanceInputAvailable}
                    className={
                      classNames(
                        !isPerformanceInputAvailable ? "bg-gray-200" : "",
                        "h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      )
                    }
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="accompanist"
                    className="font-medium text-gray-700"
                  >
                    Accompanist
                  </label>
                  <p id="accompanist-description" className="text-gray-500">
                    Does the performance require a piano accompanist?
                  </p>
                </div>
              </div>
            </fieldset>

            <div className="pt-5">
              <div className="flex justify-end">
                {!isRemoveButtonAvailable ? (
                  <button
                    type="button"
                    disabled
                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-400 cursor-not-allowed"
                  >
                    Remove
                  </button>
                ) : isRemoveSubmitting ? (
                  <button
                    type="button"
                    disabled
                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-800 cursor-not-allowed"
                  >
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    Remove
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={onRemove}
                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Remove
                  </button>
                )}

                {!isDirty || !isValid || submissionDeadlinePassed() ? (
                  <button
                    type="submit"
                    disabled
                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-400 cursor-not-allowed"
                  >
                    Save
                  </button>
                ) : isSubmitting ? (
                  <button
                    type="submit"
                    disabled
                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-800 cursor-not-allowed"
                  >
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    Save
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

function PerformanceInformation({ performance }: { performance: IPerformance }) {

  if (!performance || !performance.performance_id) {
    return <></>;
  }

  const dateTimeFormat: Intl.DateTimeFormatOptions = {
    dateStyle: "short",
    timeStyle: "short",
    timeZone: "UTC"
  }

  return (
    <div className="mt-4">
      <ul className="text-sm text-gray-500">
        <li>
          <span className="text-gray-800">Type:{" "}</span>{performance.type}
        </li>
        <li>
          <span className="text-gray-800">Description:{" "}</span>{performance.description}
        </li>
        <li>
          <span className="text-gray-800">Date:{" "}</span>{formatDate(performance.scheduled_at, dateTimeFormat)}
        </li>
        <li>
          <span className="text-gray-800">Submission deadline:{" "}</span>{formatDate(performance.submission_deadline, dateTimeFormat)}
        </li>
        {performance?.host_name && performance?.host_email && (
          <li>
            <span className="text-gray-800">Host:{" "}</span>
            <a
              className="text-gray-700 pointer-events-auto"
              href={`mailto:${encodeURIComponent(performance.host_email)
                }?subject=${encodeURIComponent(performance.description)
                }`}
            >
              {performance.host_name}
            </a>
          </li>
        )}
        {performance?.accompanist_name && performance?.accompanist_email && (
          <li>
            <span className="text-gray-800">Accompanist:{" "}</span>
            <a
              className="text-gray-700 pointer-events-auto"
              href={`mailto:${encodeURIComponent(performance.accompanist_email)
                }?subject=${encodeURIComponent(performance.description)
                }`}
            >
              {performance.accompanist_name}
            </a>
          </li>
        )}
        {performance?.location && (
          <li>
            <span className="text-gray-800">Location:{" "}</span> {performance.location}
          </li>
        )}
      </ul>
    </div>
  )
}

function SelectPerformance({
  value,
  onChange,
  options,
}: {
  value: IPerformance;
  onChange: any;
  options: IPerformance[];
}) {
  return (
    <Listbox value={value} onChange={onChange}>
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium text-gray-700">
            Select performance
          </Listbox.Label>
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 text-sm">
              <span className="block truncate">
                {value?.scheduled_at
                  ? `${value.type}: ${value.description} (${formatDate(
                    value.scheduled_at
                  )})`
                  : `Select a performance`}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm">
                {options.map((performance) => (
                  <Listbox.Option
                    key={performance.performance_id}
                    className={({ active }) =>
                      classNames(
                        active ? "text-white bg-indigo-600" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={performance}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block"
                          )}
                        >
                          {`${performance.type}: ${performance.description
                            } (${formatDate(performance.scheduled_at)})`}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

function SelectLesson({
  value,
  onChange,
  options,
}: {
  value: PerformableLesson;
  onChange: any;
  options: PerformableLesson[];
}) {
  return (
    <Listbox value={value} onChange={onChange}>
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium text-gray-700">
            Select lesson
          </Listbox.Label>
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 text-sm">
              <span className="block truncate">
                {value?.id
                  ? `${value.description}`
                  : `Select a
                lesson`}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm">
                {options.length ? (
                  options.map((lesson) => (
                    <Listbox.Option
                      key={lesson.id}
                      className={({ active }) =>
                        classNames(
                          active ? "text-white bg-indigo-600" : "text-gray-900",
                          "relative cursor-default select-none py-2 pl-3 pr-4"
                        )
                      }
                      value={lesson}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "block truncate"
                            )}
                          >
                            <div className="">
                              {`${lesson.description}`}

                              <div className="float-right">
                                {lesson?.performanceSubmissions &&
                                  lesson.performanceSubmissions.length ? (
                                  <CheckIcon height={20} color={"#08682b"} />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? "text-white" : "text-indigo-600",
                                "absolute inset-y-0 right-0 flex items-center pr-4"
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))
                ) : (
                  <Listbox.Option
                    key={"disabled"}
                    className={({ active }) =>
                      classNames(
                        active ? "text-white bg-indigo-600" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    disabled
                    value={null}
                  >
                    <>
                      <span className={"font-normal block truncate"}>
                        Select a performance
                      </span>
                    </>
                  </Listbox.Option>
                )}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

export function PerformanceRoute() {
  const [performances, setPerformances] = useState<IPerformance[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    fetch(`/api/performances/list`)
      .then((res) => res.json())
      .then((performanceData) => {

        const performancesSortedByDate = performanceData
          .sort((a: IPerformance, b: IPerformance) => {
            return (
              a.scheduled_at > b.scheduled_at ? 1 :
                b.scheduled_at > a.scheduled_at ? -1 : 0
            );
          });

        setPerformances(performancesSortedByDate);
      })
      .catch((e) => console.error(e))
      .finally(() => setIsLoading(false));
  }, []);

  if (performances && performances.length) {
    return <Performance performances={performances} />;
  } else {
    return (
      <div className="bg-white py-5">
        <div className="max-w-xl m-auto">
          <div className="mt-10 text-center">
            <p className="text-m text-gray-500">{isLoading ? 'Loading...' : 'No performances found.'}</p>
          </div>
        </div>
      </div>
    );
  }
}
