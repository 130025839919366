import React, { useEffect } from "react";
import { Redirect } from "react-router";

export const LogoutRoute = () => {
  const [loggedOut, setLoggedOut] = React.useState(false);
  const [delayFinished, setDelayFinished] = React.useState(false);

  useEffect(() => {
    let delayTimer = setTimeout(() => setDelayFinished(true), 1500);

    fetch("/api/logout", {
      method: "POST",
    }).then(() => {
      setLoggedOut(true);
    });

    // Return function for useEffect is the "cleanup" for when the component
    // gets unmounted. We want to make sure our timer doesn't hang about.
    return () => {
      clearTimeout(delayTimer);
    };
  }, []);

  if (delayFinished && loggedOut) {
    return <Redirect to={"/login"} />;
  } else {
    return (
      <div className="flex py-12 w-full justify-center items-center">
        Logging out...
      </div>
    );
  }
};
