import { ICustomer, ITeacher } from '../types';

/**
 * Create a mailto string from an object with an email property. 
 * If provided, associated objects email addresses are CC'd.
 * 
 * @param {ITeacher|ICustomer} to obj with email for 'to' field
 * @param {string} [to.email] optional email addr on obj
 * @param {Array<ITeacher|ICustomer>} [cc] optional associated obj with email for 'cc' field
 * @returns {string} a mailto string
 */

export default function mailtoStr(to: ICustomer | ITeacher, cc?: Array<ICustomer | ITeacher>): string {
  let toStr: string = '';
  let ccStr: string = '';

  if (to?.email) {
    toStr = encodeURIComponent(to?.email ? to.email : '');
  }

  if (cc && cc.length > 1) {
    ccStr = cc
      .filter(e => e?.email !== to?.email)
      .map(e => encodeURIComponent(e?.email ? e.email : ''))
      .join(', '); // comma separate multiple CC addrs
  }

  return `mailto:${toStr}${ccStr ? `?cc=${ccStr}` : ''}`;
}
