import _ from "lodash";
import React, { useEffect, useState, Fragment } from "react";
import { ILesson, ILessonInstance, ILessonRegistration } from "../types";
import { Link, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { Dialog, RadioGroup, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { useHistory } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/outline";
import formatDate from "../utils/formatDate";

type RadioGroupOption = {
  name: string;
  value: string | boolean;
  description?: string;
};

const optionsTaught: RadioGroupOption[] = [
  {
    name: "Yes - I taught this lesson",
    value: "yes",
  },
  {
    name: "No - I did not teach this lesson",
    value: "no",
  },
];

const optionsPlannedUnplanned: RadioGroupOption[] = [
  {
    name: "Planned reasons",
    value: "planned",
    description: "I expected to not teach this lesson",
  },
  {
    name: "Teacher absence",
    value: "unplanned_teacher_absence",
    description: "I expected to teach the lesson but I was absent",
  },
  {
    name: "Unplanned student absence",
    value: "unplanned_student_absence",
    description: "I expected to teach the lesson but the student was absent",
  },
  {
    name: "Unplanned trip",
    value: "unplanned_trip",
    description:
      "I expected to teach this lesson but the student was on a trip",
  },
  {
    name: "Unplanned other",
    value: "unplanned_other",
    description: "Other: please provide details in notes",
  },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface IFormInputs {
  lessonTaught: string;
  plannedAbsence?: string;
  unplannedLessonExplanation?: string;
  lessonNotes?: string;
  lessonInstanceID: number | null;
}

export const RegisterRoute = () => {
  const [lesson, setLessonData] = useState<ILesson | null>(null);
  const [registrationHistoryModalOpen, setRegistrationHistoryModalOpen] =
    useState<boolean>(false);
  const [saved, setSaved] = useState<boolean>(false);
  const { lessonID } = useParams<{ lessonID: string }>();

  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    formState: { isSubmitting, isDirty, isValid, errors },
  } = useForm<IFormInputs>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      lessonTaught: "",
      plannedAbsence: "",
      unplannedLessonExplanation: "",
      lessonNotes: "",
      lessonInstanceID: null
    }
  });

  const onSubmit = async (data: IFormInputs) => {
    console.log("SUBMITTING", data);

    await fetch(`/api/registrations`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        lesson_instance_id: data.lessonInstanceID,
        taught: data.lessonTaught === "yes",
        not_taught_reason: data.plannedAbsence,
        not_taught_reason_desc: data.unplannedLessonExplanation,
        lesson_notes: data.lessonNotes,
      }),
    })
      .then((res) => res.json())
      .then((data) => setSaved(true));
  };

  useEffect(() => {
    fetch(`/api/lessons/individual/${lessonID}`)
      .then((res) => res.json())
      .then((data) => setLessonData(data.lesson));
  }, [lessonID]);

  let history = useHistory();

  if (_.isEmpty(lesson)) {
    return <div className="flex justify-center">Loading...</div>;
  }

  const lessonTaught = watch("lessonTaught");
  const plannedAbsence = watch("plannedAbsence");

  if (saved) {
    return (
      <div className="max-w-xl m-auto">
        <div className="my-12">
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
            <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <div className="text-lg leading-6 font-medium text-gray-900">
              Registration Confirmed
            </div>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                We've recorded your register entry for this lesson, thanks!
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-6 flex flex-col space-y-4 sm:flex-row-reverse sm:space-y-0">
          <Link
            type="button"
            className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm flex-1 sm:ml-4"
            to={"/lessons/today"}
          >
            Today's lessons
          </Link>
          <Link
            type="button"
            className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 border-gray-300 text-base font-medium hover:border-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:text-sm flex-1"
            to={"/schools"}
          >
            All lessons
          </Link>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="bg-white py-5  sm:px-6">
        <h3 className="text-xl font-semibold text-gray-900 mb-1">
          Register Lesson
        </h3>
        <h4 className="text-gray-900 font-extralight">
          {`${lesson.school.name}`}
        </h4>
        <h5 className="text-gray-900 font-light">
          {`${lesson.student.first_name} ${lesson.student.last_name}`}
        </h5>
        <div className="mt-4">
          <button
            onClick={() => setRegistrationHistoryModalOpen(true)}
            type="button"
            className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Registration history
          </button>
        </div>
        {registrationHistoryModalOpen && (
          <RegistrationHistoryModal
            open={registrationHistoryModalOpen}
            setOpen={setRegistrationHistoryModalOpen}
            lessonInstances={lesson.lessonInstances || []}
          />
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-4">
            <label
              htmlFor="lessonInstanceID"
              className="block text-sm font-medium text-gray-700"
            >
              Which lesson did you teach?
            </label>
            <select
              id="lessonInstanceID"
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              {...register("lessonInstanceID", {
                required: true,
              })}
              defaultValue={""}
            >
              <option key={"disabled"} disabled value="">
                -- Select day --
              </option>

              {lesson?.lessonInstances && lesson.lessonInstances
                .filter(
                  (lessonInstance: ILessonInstance) =>
                    !lessonInstance.lessonRegistration
                )
                .sort((a: ILessonInstance, b: ILessonInstance) =>
                  a.scheduled_at < b.scheduled_at
                    ? -1
                    : a.scheduled_at > b.scheduled_at
                      ? 1
                      : 0
                )
                .map((lessonInstance: ILessonInstance) => (
                  <option key={lessonInstance.id} value={lessonInstance.id}>
                    {formatDate(lessonInstance.scheduled_at)}
                  </option>
                ))}
            </select>
          </div>
          <div className="mt-6">
            <label
              htmlFor="lessonTaught"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Was the lesson taught?
            </label>
            <Controller
              control={control}
              name="lessonTaught"
              rules={{ required: true }}
              render={({ field: { onChange, value, ref } }) => (
                <RadioGroupList
                  onChange={onChange}
                  value={value}
                  options={optionsTaught}
                />
              )}
            />
          </div>

          {lessonTaught === "no" ? (
            <div className="mt-6">
              <label
                htmlFor="lessonTaught"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Why was the lesson not taught?
              </label>
              <Controller
                control={control}
                name="plannedAbsence"
                rules={{ required: true }}
                render={({ field: { onChange, value, ref } }) => (
                  <RadioGroupList
                    onChange={onChange}
                    value={value}
                    options={optionsPlannedUnplanned}
                  />
                )}
              />
            </div>
          ) : null}

          {lessonTaught === "no" &&
            (plannedAbsence === "unplanned_other" ||
              plannedAbsence === "unplanned_trip") ? (
            <div className="mt-6">
              <label
                htmlFor="about"
                className="block text-sm font-medium text-gray-700"
              >
                Why couldn't the lesson go ahead?
              </label>
              <div className="mt-1">
                <textarea
                  id="unplannedLessonExplanation"
                  rows={3}
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                  defaultValue={""}
                  {...register("unplannedLessonExplanation", {
                    required: true,
                  })}
                  placeholder={"e.g., The student forgot their book"}
                />
              </div>
            </div>
          ) : null}

          {lessonTaught === "yes" ||
            (lessonTaught === "no" && plannedAbsence !== undefined) ? (
            <div className="mt-6">
              <label
                htmlFor="lessonNotes"
                className="block text-sm font-medium text-gray-700"
              >
                Lesson Notes
              </label>
              <div className="mt-1">
                <textarea
                  id="lessonNotes"
                  rows={3}
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                  defaultValue={""}
                  {...register("lessonNotes")}
                  placeholder={
                    "Lesson notes - be aware these will be shared with parents"
                  }
                />
              </div>
            </div>
          ) : null}

          <div className="pt-5">
            <div className="flex justify-end">
              <button
                type="button"
                onClick={() => history.goBack()}
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Cancel
              </button>

              {!isDirty || !isValid ? (
                <button
                  type="submit"
                  disabled
                  className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 cursor-not-allowed"
                >
                  Save
                </button>
              ) : isSubmitting ? (
                <button
                  type="submit"
                  disabled
                  className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-800 cursor-not-allowed"
                >
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Save
                </button>
              ) : (
                <button
                  type="submit"
                  className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

const RadioGroupList = ({
  value,
  onChange,
  options,
}: {
  value: any;
  onChange: any;
  options: RadioGroupOption[];
}): React.ReactElement => {
  console.log({ value, onChange, options });
  if (!options) {
    return <></>;
  }

  return (
    <RadioGroup value={value} onChange={onChange}>
      <div className="bg-white rounded-md -space-y-px">
        {options.map((option, optionIdx) => (
          <RadioGroup.Option
            key={option.name}
            value={option.value}
            className={({ checked }) =>
              classNames(
                optionIdx === 0 ? "rounded-tl-md rounded-tr-md" : "",
                optionIdx === options.length - 1
                  ? "rounded-bl-md rounded-br-md"
                  : "",
                checked
                  ? "bg-indigo-50 border-indigo-200 z-10"
                  : "border-gray-200",
                "relative border p-3 flex cursor-pointer focus:outline-none"
              )
            }
          >
            {({ active, checked }) => (
              <>
                <span
                  className={classNames(
                    checked
                      ? "bg-indigo-600 border-transparent"
                      : "bg-white border-gray-300",
                    active ? "ring-2 ring-offset-2 ring-indigo-500" : "",
                    "h-4 w-4 cursor-pointer rounded-full border flex self-center items-center justify-center flex-shrink-0"
                  )}
                  aria-hidden="true"
                >
                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                </span>
                <div className="ml-3 flex flex-col">
                  <RadioGroup.Label
                    as="span"
                    className={classNames(
                      checked ? "text-indigo-900" : "text-gray-900",
                      "block text-sm font-medium"
                    )}
                  >
                    {option.name}
                  </RadioGroup.Label>

                  {option.description ? (
                    <RadioGroup.Description
                      as="span"
                      className={classNames(
                        checked ? "text-indigo-700" : "text-gray-500",
                        "block text-sm"
                      )}
                    >
                      {option.description}
                    </RadioGroup.Description>
                  ) : null}
                </div>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

function RegistrationHistoryModal({
  open,
  setOpen,
  lessonInstances,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  lessonInstances: ILessonInstance[];
}) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative flex flex-col max-h-80vh px-2 w-full max-w-sm  sm:w-full text-center transform rounded-lg bg-white shadow-xl transition-all">
                <div className="inline-flex my-3 mx-2">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Registration history
                  </Dialog.Title>

                  <button
                    type="button"
                    onClick={() => setOpen(false)}
                    className="fixed right-3 justify-center rounded-md border border-transparent bg-white text-gray-600 border border-gray-100 shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-inset-2"
                  >
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {lessonInstances.filter(
                  (lessonInstance) => lessonInstance?.lessonRegistration
                ).length ? (
                  <Dialog.Panel className="flex-1 mb-2 overflow-y-scroll border border-gray-200 transform rounded-lg bg-white text-left shadow-xl transition-all sm:w-full sm:max-w-sm">
                    <div>
                      <ul role="list" className="divide-y divide-gray-200">
                        {lessonInstances
                          .sort(
                            (a, b) =>
                              new Date(a.scheduled_at).getTime() -
                              new Date(b.scheduled_at).getTime()
                          )
                          .map((lessonInstance) => (
                            <RegistrationHistoryListItem
                              key={lessonInstance.id}
                              lessonInstance={lessonInstance}
                            />
                          ))}
                      </ul>
                    </div>
                  </Dialog.Panel>
                ) : (
                  <Dialog.Panel className="flex-1 mb-2 overflow-y-scroll transform rounded-lg bg-white text-center transition-all sm:w-full sm:max-w-sm">
                    <div className="my-6">
                      <p>No lesson registrations found.</p>
                    </div>
                  </Dialog.Panel>
                )}
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function RegistrationHistoryListItem({
  lessonInstance,
}: {
  lessonInstance: ILessonInstance;
}) {
  const lessonRegistration = lessonInstance?.lessonRegistration;
  if (!lessonRegistration) {
    return <></>;
  }

  function formatNotTaughtReason(
    notTaughtReason: ILessonRegistration["not_taught_reason"]
  ) {
    if (!notTaughtReason) return "";
    // unplanned_trip and unplanned_other are altered after submission by the
    // admin team to add a suffix of the billable party. This is irrelevant
    // to teachers and should be stripped from the reason.
    if (notTaughtReason.startsWith("unplanned_trip")) {
      return "Unplanned trip";
    }

    if (notTaughtReason.startsWith("unplanned_other")) {
      return "Unplanned other";
    }

    switch (notTaughtReason) {
      case "planned": {
        return "Planned";
      }
      case "unplanned_teacher_absence": {
        return "Teacher absence";
      }
      case "unplanned_student_absence": {
        return "Student absence";
      }
      case "unplanned_student_absence": {
        return "Student absence";
      }
      default:
        return "";
    }
  }

  return (
    <li key={lessonInstance.id} className="py-2 text-left">
      <h4 className="text-sm font-semibold mx-2 text-gray-900">
        {formatDate(lessonInstance.scheduled_at)}
      </h4>
      <p className="text-sm mt-1 mx-2 text-gray-800">
        <span className="font-medium text-gray-600">Taught: </span>
        {lessonRegistration.taught ? "True" : "False"}
      </p>
      {!lessonRegistration.taught && (
        <>
          <p className="text-sm mx-2 text-gray-800">
            <span className="font-medium text-gray-600">Reason: </span>
            {formatNotTaughtReason(lessonRegistration.not_taught_reason)}
          </p>
          {lessonRegistration.not_taught_reason_desc && (
            <p className="text-sm mx-2 text-gray-800">
              <span className="font-medium text-gray-600">Note: </span>
              {lessonRegistration.not_taught_reason_desc}
            </p>
          )}
        </>
      )}
    </li>
  );
}
