import { useState, useEffect, Fragment, useRef } from 'react';
import { IStudent, IStudentReport } from '../types';
import { Controller, useForm } from "react-hook-form";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

interface IFormInputs {
  student: IStudent;
  achievements: string;
  targets: string;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export function StudentReport({
  students,
  setIsSubmitSuccessful
}: {
  students: IStudent[],
  setIsSubmitSuccessful: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const [isTextInputAvailable, setIsTextInputAvailable] = useState<boolean>(false);
  const [isStudentReportUpdate, setIsStudentReportUpdate] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    resetField,
    watch,
    reset,
    control,
    setValue,
    formState: { isSubmitting, isDirty, isValid, isSubmitSuccessful },
  } = useForm<IFormInputs>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      student: {},
      achievements: "",
      targets: "",
    },
  });

  const selectedStudent = watch("student");

  // Check for a previously submitted StudentReport for the selected student.
  useEffect(() => {
    if (selectedStudent && selectedStudent?.id) {
      resetField("achievements");
      resetField("targets");
      // Text inputs are disabled until a Student is selected
      setIsTextInputAvailable(true);
      fetch(`/api/students/reports/${selectedStudent.id}`)
        .then((res) => res.json())
        .then((existingStudentReport: IStudentReport) => {
          if (existingStudentReport?.achievements) {
            setValue("achievements", existingStudentReport.achievements, {
              shouldValidate: true
            });
          }
          if (existingStudentReport?.targets) {
            setValue("targets", existingStudentReport.targets, {
              shouldValidate: true
            });
          }
          // If an existing student report exists, the submit button
          // should indicate this in an update not a save.
          setIsStudentReportUpdate(true);
        })
        .catch(() => setIsStudentReportUpdate(false));
    } else {
      setIsTextInputAvailable(false);
    }
  }, [selectedStudent]);

  // Reset the form after submit.
  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
      setIsSubmitSuccessful(false);
    }
  }, [isSubmitSuccessful])

  function validateTargetsField(targets: string, formData: IFormInputs) {
    // At least one of the text input fields must be provided and not be whitespace
    if (!targets.trim() && !formData?.achievements.trim()) {
      return false;
    }

    const existingStudentReport = selectedStudent?.studentReports;
    if (existingStudentReport?.length) {
      const {
        achievements: existingAchievements,
        targets: existingTargets
      } = existingStudentReport[0];

      if (
        formData.achievements === (existingAchievements ?? "") &&
        targets === (existingTargets ?? "")
      ) {
        return false;
      }
    }
    return true;
  }

  function validateAchievementsField(achievements: string, formData: IFormInputs) {
    // At least one of the text input fields must be provided and not be whitespace
    if (!achievements.trim() && !formData?.targets.trim()) {
      return false;
    }

    const existingStudentReport = selectedStudent?.studentReports;
    if (existingStudentReport?.length) {
      // If an existing StudentReport exists, at least one field must be different
      const {
        achievements: existingAchievements,
        targets: existingTargets
      } = existingStudentReport[0];

      if (
        achievements === (existingAchievements ?? "") &&
        formData.targets === (existingTargets ?? "")
      ) {
        return false;
      }
    }
    return true;
  }

  async function onSubmit(formData: IFormInputs) {
    const studentID = formData?.student?.id;
    if (studentID) {
      await fetch(`/api/students/reports/submit`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          student_id: studentID,
          achievements: formData?.achievements,
          targets: formData?.targets,
        }),
      })
        .then((res) => res.json())
        .then(() => setIsSubmitSuccessful(true)) // trigger refresh of StudentReportList
        .catch((e) => console.error(e))
        .finally(() => setIsStudentReportUpdate(false));
    }
  }

  return (
    <>
      <div className="max-w-xl m-auto">
        <div className="bg-white py-5 sm:px-6">
          <h3 className="text-xl font-semibold text-gray-900 mb-4">
            Submit student report
          </h3>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-4">
              <Controller
                name="student"
                rules={{
                  required: true
                }}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <SelectStudent
                    value={value}
                    onChange={onChange}
                    options={students}
                  />
                )}
              />
            </div>

            <div className="mt-4">
              <label
                htmlFor="achievements"
                className="block text-sm font-medium leading-6 text-gray-700"
              >
                Achievements this year
              </label>
              <div className="mt-1">
                <textarea
                  {...register("achievements", {
                    required: false,
                    validate: validateAchievementsField
                  })}
                  rows={6}
                  id="achievements"
                  disabled={!isTextInputAvailable}
                  className={
                    classNames(
                      !isTextInputAvailable ? "bg-gray-200" : "",
                      "block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:py-1.5 text-sm sm:leading-6"
                    )
                  }
                  aria-describedby="achievements-input"
                  placeholder={!isTextInputAvailable ? "Select a student..." : "Enter achievements..."}
                />
              </div>
              <p
                className="mt-2 text-sm text-gray-500"
                id="achievements-input"
              >
                Provide a summary of your experience teaching {!isTextInputAvailable ? "the student" : `${selectedStudent.first_name}`} this year.
                Consider their performances, practice quality/regularity, technique,
                theory understanding and whether they complete their practice diary regularly.
              </p>
            </div>

            <div className="mt-4">
              <label
                htmlFor="targets"
                className="block text-sm font-medium leading-6 text-gray-700"
              >
                Targets going forward
              </label>
              <div className="mt-1">
                <textarea
                  {...register("targets", {
                    required: false,
                    validate: validateTargetsField
                  })}
                  rows={5}
                  id="targets"
                  disabled={!isTextInputAvailable}
                  className={
                    classNames(
                      !isTextInputAvailable ? "bg-gray-200" : "",
                      "block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:py-1.5 text-sm sm:leading-6"
                    )
                  }
                  aria-describedby="targets-input"
                  placeholder={!isTextInputAvailable ? "Select a student..." : "Enter targets..."}
                />
              </div>
              <p
                className="mt-2 text-sm text-gray-500"
                id="targets-input"
              >
                Provide targets and set out goals for the future {!isTextInputAvailable ? "for the student. " : `for ${selectedStudent.first_name}. `}
                Consider their past performances, grade exams and technical achievements this year.
              </p>
            </div>

            <div className="pt-5">
              <div className="flex justify-end">
                {!isDirty || !isValid ? (
                  <button
                    type="submit"
                    disabled
                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-400 cursor-not-allowed"
                  >
                    {isStudentReportUpdate ? "Update" : "Save"}
                  </button>
                ) : isSubmitting ? (
                  <button
                    type="submit"
                    disabled
                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-800 cursor-not-allowed"
                  >
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    {isStudentReportUpdate ? "Update" : "Save"}
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    {isStudentReportUpdate ? "Update" : "Save"}
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div >
    </>
  );
}

/**
 * Return an icon indicating the current status of a student's report. A
 * warning sign indicates there are fields remaining to complete and a check
 * icon indicates both fields are complete.
 * 
 * NOTE: this is a best guess at the status of the field. In absence of a
 * word limit or another guide indicating completion, we simply check whether
 * there is any input at all.
 * 
 * @param {IStudent} student 
 * @returns a hero icon component
 */

function studentReportProgressIndicator(student: IStudent) {
  if (!student?.studentReports || !student.studentReports.length) {
    return <></>;
  }
  if (
    student.studentReports[0].targets &&
    student.studentReports[0].achievements
  ) {
    return <CheckIcon height={20} color={"#08682b"} />
  } else {
    return <ExclamationTriangleIcon height={20} color={"#D56001"} />
  }
}

function SelectStudent({
  value,
  onChange,
  options,
}: {
  value: IStudent;
  onChange: any;
  options: IStudent[];
}) {
  return (
    <Listbox value={value} by="id" onChange={onChange}>
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium text-gray-700">
            Select student
          </Listbox.Label>
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 text-sm">
              <span className="block truncate">
                {value?.id
                  ? `${value.first_name} ${value.last_name}`
                  : `Select a
                student`}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm">
                {options.length ? (
                  options.map((student) => (
                    <Listbox.Option
                      key={student.id}
                      className={({ active }) =>
                        classNames(
                          active ? "text-white bg-indigo-600" : "text-gray-900",
                          "relative cursor-default select-none py-2 pl-3 pr-4"
                        )
                      }
                      disabled={value?.id === student.id}
                      value={student}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "block truncate"
                            )}
                          >
                            {`${student.first_name} ${student.last_name}`}
                            <div className="float-right">
                              {studentReportProgressIndicator(student)}
                            </div>
                          </span>
                        </>
                      )}
                    </Listbox.Option>
                  ))
                ) : (
                  <Listbox.Option
                    key={"disabled"}
                    className={({ active }) =>
                      classNames(
                        active ? "text-white bg-indigo-600" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    disabled
                    value={null}
                  >
                    <>
                      <span className={"font-normal block truncate"}>
                        No students found
                      </span>
                    </>
                  </Listbox.Option>
                )}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

export function StudentReportRoute() {

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState<boolean>(true);

  // pass a ref to the student data array to prevent the Listbox component
  // re-rendering the array and resetting the 'selected' item state.
  const ref = useRef<IStudent[]>([]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      setIsLoading(true);
      fetch(`/api/students/reports/list`)
        .then((res) => res.json())
        .then((students) => ref.current = students)
        .catch((e) => console.error(e))
        .finally(() => {
          setIsLoading(false)
          setIsSubmitSuccessful(false);
        });
    }
  }, [isSubmitSuccessful]);

  if (ref.current && ref.current.length) {
    return (
      <StudentReport students={ref.current} setIsSubmitSuccessful={setIsSubmitSuccessful} />
    );
  } else {
    return (
      <div className="bg-white py-5">
        <div className="max-w-xl m-auto">
          <div className="mt-10 text-center">
            <p className="text-m text-gray-500">{
              isLoading ? 'Loading...' : 'No pending student reports.'
            }</p>
          </div>
        </div>
      </div>
    );
  }
}
