import { useState, useEffect, Fragment } from "react";
import { Control, Controller, UseFormRegister, useForm } from "react-hook-form";
import { Listbox, RadioGroup, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Accompanist, Month as ExamMonth, ExamSeason, ExamType, ExamGrade, ILesson, RadioOption, IExam, IStudent, SelectOption } from "../types";
import { examMonthOptions } from "../constants";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

function formatCurrency(pence: number) {
  return new Intl.NumberFormat("en-GB", {
    style: "currency", currency: "GBP"
  }).format(pence / 100);
}

type FormValues = {
  examID: IExam["id"] | null;
  examType: ExamType | null;
  studentID: IStudent["id"] | null;
  examSeason: ExamSeason | null;
  examMonth: ExamMonth | null;
  lessonID: ILesson["id"] | null;
  accompanist: Accompanist | null;
  additionalNeeds: string;
}

const examTypeOptions: RadioOption<FormValues>[] = [
  {
    name: ExamType[ExamType.Practical],
    value: ExamType.Practical,
  },
  {
    name: ExamType[ExamType.Performance],
    value: ExamType.Performance,
  },
  {
    name: ExamType[ExamType.Theory],
    value: ExamType.Theory,
  },
];

const examSeasonOptions: RadioOption<FormValues>[] = [
  {
    name: ExamSeason[ExamSeason.Winter],
    value: ExamSeason.Winter
  },
  {
    name: ExamSeason[ExamSeason.Spring],
    value: ExamSeason.Spring
  },
  {
    name: ExamSeason[ExamSeason.Summer],
    value: ExamSeason.Summer
  }
];

const accompanistOptions: RadioOption<FormValues>[] = [
  {
    name: Accompanist[Accompanist.True],
    value: Accompanist.True
  },
  {
    name: Accompanist[Accompanist.False],
    value: Accompanist.False
  }
]

function RadioInput({
  name,
  label,
  control,
  options
}: {
  name: keyof FormValues,
  label: string
  control: Control<FormValues>,
  options: RadioOption<FormValues>[]
}): JSX.Element {
  return (
    <div className="mt-6">
      <label
        htmlFor={name}
        className="block text-sm font-medium text-gray-700 mb-1"
      >
        {label}
      </label>
      <Controller
        control={control}
        name={name}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <RadioGroupList
            onChange={onChange}
            value={value}
            options={options}
          />
        )}
      />
    </div>
  )
}

function SelectInput({
  name,
  label,
  control,
  options
}: {
  name: keyof FormValues,
  label: string
  control: Control<FormValues>,
  options: SelectOption<FormValues>[]
}): JSX.Element {
  return (
    <div className="mt-6">
      <label
        htmlFor={name}
        className="block text-sm font-medium text-gray-700 mb-1"
      >
        {label}
      </label>
      <Controller
        control={control}
        name={name}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <SelectList
            onChange={onChange}
            value={value}
            options={options}
          />
        )}
      />
    </div>
  )
}

function TextArea({
  name,
  label,
  register,
  placeholder = ""
}: {
  name: keyof FormValues;
  label: string;
  register: UseFormRegister<FormValues>;
  placeholder?: string;
}) {
  return (
    <div className="mt-6">
      <label
        htmlFor="additionalNeeds"
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <div className="mt-1">
        <textarea
          id={name}
          rows={3}
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
          defaultValue={""}
          {...register(name)}
          placeholder={placeholder}
        />
      </div>
    </div>
  )
}

function SubmitButton({
  isDirty,
  isValid,
  isSubmitting
}: {
  isDirty: boolean;
  isValid: boolean;
  isSubmitting: boolean;
}) {
  return (
    <div className="pt-5">
      <div className="flex justify-end">
        {(!isDirty || !isValid) ? (
          <button
            type="submit"
            disabled
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-400 cursor-not-allowed"
          >
            Save
          </button>
        ) : isSubmitting ? (
          <button
            type="submit"
            disabled
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-800 cursor-not-allowed"
          >
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            Save
          </button>
        ) : (
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Save
          </button>
        )}
      </div>
    </div>
  )
}

function ExamSubmission({ exams, students }: { exams: IExam[], students: IStudent[] }) {
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [lessonOptions, setLessonOptions] = useState<RadioOption<FormValues>[]>([]);

  const defaultValues = {
    examID: null,
    examType: null,
    examMonth: null,
    studentID: null,
    examSeason: null,
    lessonID: null,
    accompanist: null,
    additionalNeeds: ""
  }

  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    formState: { isSubmitting, isSubmitSuccessful, isDirty, isValid },
  } = useForm<FormValues>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues
  });

  const examType = watch("examType");
  const examMonth = watch("examMonth");
  const examSeason = watch("examSeason");
  const examGrade = watch("examID");
  const lessonID = watch("lessonID");
  const studentID = watch("studentID");

  const theoryGradeExamOptions: RadioOption<FormValues>[] = exams
    .filter((exam) => {
      return (
        exam.type === ExamType.Theory &&
        ExamGrade[exam.grade] &&
        (exam.grade !== ExamGrade.Prep) && // Theory exams do not offer Prep or Initial grades
        (exam.grade !== ExamGrade.Initial)
      )
    })
    .map((exam) => ({
      name: ExamGrade[exam.grade],
      value: exam.id,
      description: formatCurrency(exam.customer_amount)
    }));

  const practicalGradeExamOptions: RadioOption<FormValues>[] = exams
    .filter((exam) => exam.type === ExamType.Practical && ExamGrade[exam.grade])
    .map((exam) => ({
      name: ExamGrade[exam.grade],
      value: exam.id,
      description: formatCurrency(exam.customer_amount)
    }));

  const performanceGradeExamOptions: RadioOption<FormValues>[] = exams
    .filter((exam) => {
      return (
        exam.type === ExamType.Performance &&
        ExamGrade[exam.grade] &&
        (exam.grade !== ExamGrade.Prep) // Performance exams do not offer Prep grades
      )
    })
    .map((exam) => ({
      name: ExamGrade[exam.grade],
      value: exam.id,
      description: formatCurrency(exam.customer_amount)
    }));

  const studentList: RadioOption<FormValues>[] = students.map((student) => ({
    name: `${student.first_name} ${student.last_name}`,
    value: student.id
  }));

  useEffect(() => {
    const selectedStudent = students.find((s) => s.id === studentID);
    if (selectedStudent && selectedStudent?.lessons) {
      const lessonOptions = selectedStudent.lessons.map((lesson) => {
        return {
          name: lesson?.lessonSubject.subject,
          value: lesson.id
        }
      });
      setLessonOptions(lessonOptions);
    }
  }, [studentID]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(defaultValues);
    }
  }, [isSubmitSuccessful, reset]);

  // Reset all values except student and examType if the examType is changed
  // so type specific values (e.g. requested season or month) are also reset
  useEffect(() => {
    reset({
      ...defaultValues,
      examType: examType,
      studentID: studentID
    })
  }, [examType])

  function isPianoLesson() {
    const selectedStudent = students.find((s) => s.id === studentID);
    if (selectedStudent && selectedStudent.lessons) {
      const selectedLesson = selectedStudent.lessons.find((l) => l.id === lessonID);
      const selectedLessonSubjectID = selectedLesson?.lessonSubject.subject;
      if (selectedLessonSubjectID?.toUpperCase() === "PIANO") {
        return true;
      }
    }
    return false;
  }

  async function onSubmit(data: FormValues) {
    // Exam submission are associated with students and teachers by lesson ID. The
    // instrument is not relevant for Theory exams we can use the first lesson
    await fetch(`/api/exams/submit`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        exam_id: data.examID,
        lesson_id: data.lessonID ?? lessonOptions[0].value,
        exam_season: data.examSeason,
        exam_month: data.examMonth,
        accompanist: data.accompanist,
        additional_needs: data.additionalNeeds
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        setShowSuccessMessage(true)
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 2000);
      })
      .catch((e) => {
        setShowErrorMessage(true)
        setTimeout(() => {
          setShowErrorMessage(false);
        }, 2000);
        console.error(e)
      });
  }

  return (
    <div className="max-w-xl m-auto">
      <div className="bg-white py-5 sm:px-6">
        <h3 className="text-xl font-semibold text-gray-900 mb-4">
          Exam submissions
        </h3>

        {showSuccessMessage && (
          <span className="inline-flex items-center gap-x-0.5 rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700">
            Submitted
            <button type="button" onClick={() => setShowSuccessMessage(false)} className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-green-200">
              <span className="sr-only">Remove</span>
              <svg aria-hidden="true" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 18L18 6M6 6l12 12" strokeLinecap="round" strokeLinejoin="round"></path>
              </svg>
              <span className="absolute -inset-1" />
            </button>
          </span>
        )}

        {showErrorMessage && (
          <span className="inline-flex items-center gap-x-0.5 rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-700">
            Error: please try again.
            <button type="button" onClick={() => setShowErrorMessage(false)} className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-red-200">
              <span className="sr-only">Remove</span>
              <svg aria-hidden="true" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 18L18 6M6 6l12 12" strokeLinecap="round" strokeLinejoin="round"></path>
              </svg>
              <span className="absolute -inset-1" />
            </button>
          </span>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>

          <SelectInput
            name={"studentID"}
            label={"Select student"}
            control={control}
            options={studentList}
          />

          <RadioInput
            name={"examType"}
            label={"Select exam type"}
            control={control}
            options={examTypeOptions}
          />
          {examType && (
            <>
              {examType === ExamType.Practical ? (

                <RadioInput
                  name={"examSeason"}
                  label={"Select season"}
                  control={control}
                  options={examSeasonOptions}
                />
              ) : (
                <SelectInput
                  name={"examMonth"}
                  label={"Select month"}
                  control={control}
                  options={examMonthOptions}
                />
              )}

              {(examMonth || examSeason) && (
                <>
                  <SelectInput
                    name={"examID"}
                    label={"Select grade"}
                    control={control}
                    options={
                      examType === ExamType.Theory ? theoryGradeExamOptions
                        : examType === ExamType.Practical ? practicalGradeExamOptions
                          : performanceGradeExamOptions
                    }
                  />
                  <p className="mt-2 text-sm text-gray-500">
                    By proceeding you confirm that you have communicated the cost of the exam.
                  </p>
                  {(examType !== ExamType.Theory && examGrade) && (
                    <>
                      <RadioInput
                        name={"lessonID"}
                        label={"Select lesson subject"}
                        control={control}
                        options={lessonOptions}
                      />
                      {(lessonID && !isPianoLesson()) && (
                        <RadioInput
                          name={"accompanist"}
                          label={"Requires accompanist?"}
                          control={control}
                          options={accompanistOptions}
                        />
                      )}
                    </>
                  )}

                  {((examType === ExamType.Theory && examMonth && examGrade) ||
                    (examType === ExamType.Practical || examType === ExamType.Performance) && lessonID) && (
                      <>
                        <TextArea
                          name={"additionalNeeds"}
                          label={"Additional information"}
                          register={register}
                        />
                        <p className="mt-2 text-sm text-gray-500">
                          Provide additional information that may impact the student's exam, such
                          as any additional needs, or dates they are unavailable.
                        </p>
                        <p className="mt-2 text-sm text-gray-500">
                          Please only submit a student for an exam after discussing the requirements with their
                          parent or guardian. An automated email notification will be sent to confirm details.
                        </p>
                        <SubmitButton isDirty={isDirty} isValid={isValid} isSubmitting={isSubmitting} />
                      </>
                    )}
                </>
              )}
            </>
          )}
        </form>
      </div>
    </div >
  )
}

const RadioGroupList = ({
  value,
  onChange,
  options
}: {
  value: FormValues[keyof FormValues];
  onChange: (...event: any[]) => void;
  options: RadioOption<FormValues>[],
}): React.ReactElement => {

  if (!options) {
    return <></>
  }

  return (
    <RadioGroup value={value} onChange={onChange}>
      <div className="bg-white rounded-md -space-y-px">
        {options.map((option: any, optionIdx: any) => (
          <RadioGroup.Option
            key={option.value}
            value={option.value}
            className={({ checked }) =>
              classNames(
                optionIdx === 0 ? "rounded-tl-md rounded-tr-md" : "",
                optionIdx === options.length - 1
                  ? "rounded-bl-md rounded-br-md"
                  : "",
                checked
                  ? "bg-indigo-50 border-indigo-200"
                  : "border-gray-200",
                "relative border p-3 flex cursor-pointer focus:outline-none"
              )
            }
          >
            {({ active, checked }) => (
              <>
                <span
                  className={classNames(
                    checked
                      ? "bg-indigo-600 border-transparent"
                      : "bg-white border-gray-300",
                    active ? "ring-2 ring-offset-2 ring-indigo-500" : "",
                    "h-4 w-4 cursor-pointer rounded-full border flex self-center items-center justify-center flex-shrink-0"
                  )}
                  aria-hidden="true"
                >
                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                </span>
                <div className="ml-3 flex flex-col">
                  <RadioGroup.Label
                    as="span"
                    className={classNames(
                      checked ? "text-indigo-900" : "text-gray-900",
                      "block text-sm font-medium"
                    )}
                  >
                    {option.name}
                  </RadioGroup.Label>

                  {option?.description ? (
                    <RadioGroup.Description
                      as="span"
                      className={classNames(
                        checked ? "text-indigo-700" : "text-gray-500",
                        "block text-sm"
                      )}
                    >
                      {option.description}
                    </RadioGroup.Description>
                  ) : null}
                </div>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

function SelectList({
  value,
  onChange,
  options,
}: {
  value: FormValues[keyof FormValues];
  onChange: (...event: any[]) => void;
  options: SelectOption<FormValues>[],
}) {

  if (!options) {
    return <></>
  }

  return (
    <Listbox value={value} onChange={onChange}>
      {({ open }) => (
        <div className="relative mt-1">
          <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 text-sm">
            <span className="block truncate">
              {value ? options.find((o) => o.value === value)?.name : `Select an option`}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm">
              {options.length ? (
                options.map((option) => (
                  <Listbox.Option
                    key={option.value}
                    className={({ active }) =>
                      classNames(
                        active ? "text-white bg-indigo-600" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-4"
                      )
                    }
                    value={option.value}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {option.name}
                        </span>

                        {option?.description ? (
                          <span
                            className={classNames(
                              "text-gray-500",
                              "block text-sm"
                            )}
                          >
                            {option.description}
                          </span>
                        ) : null}

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))
              ) : (
                <Listbox.Option
                  key={"disabled"}
                  className={({ active }) =>
                    classNames(
                      active ? "text-white bg-indigo-600" : "text-gray-900",
                      "relative cursor-default select-none py-2 pl-3 pr-9"
                    )
                  }
                  disabled
                  value={null}
                >
                  <>
                    <span className={"font-normal block truncate"}>
                      Select an option
                    </span>
                  </>
                </Listbox.Option>
              )}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}

export function ExamSubmissionRoute() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [exams, setExams] = useState<IExam[] | null>(null);
  const [students, setStudents] = useState<IStudent[] | null>(null);

  useEffect(() => {
    setIsLoading(true);

    fetch('/api/exams/list')
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((exams) => setExams(exams))
      .catch((e) => console.error(e));

    fetch("/api/students/list")
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((exams) => setStudents(exams))
      .catch((e) => console.error(e));

    setIsLoading(false);
  }, []);

  if ((exams && exams.length) && (students && students.length)) {
    return <ExamSubmission exams={exams} students={students} />;
  } else {
    return (
      <div className="bg-white py-5">
        <div className="max-w-xl m-auto">
          <div className="mt-10 text-center">
            <p className="text-m text-gray-500">{isLoading && 'Loading...'}</p>
          </div>
        </div>
      </div>
    );
  }
}
