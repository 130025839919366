import { useEffect, useState } from "react";
import { IStudent, ILesson } from "../types";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import mailtoStr from "../utils/mailtoStr";
import _ from "lodash";

interface StudentData extends IStudent {
  lessons: ILesson[];
}

function Students({ studentList }: { studentList: StudentData[] | null }) {
  if (!studentList) {
    return null;
  }

  interface GroupedStudent {
    [schoolName: string]: {
      students: Record<number, IStudent>;
    };
  }

  const groupedStudents = _.reduce(
    studentList,
    (grouped: GroupedStudent, student: StudentData) => {
      _.map(student.lessons, (lesson) => {
        if (lesson?.school?.name) {
          if (lesson?.school?.name in grouped) {
            if (!(student.id in grouped[lesson.school.name].students)) {
              grouped[lesson.school.name].students[student.id] = student;
            }
          } else {
            if (lesson?.school?.name) {
              grouped[lesson.school.name] = {
                students: {
                  [student.id]: student,
                },
              };
            }
          }
        }
      });
      return grouped;
    },
    {}
  );

  return (
    <div className="w-full mx-auto flex flex-row justify-center items-center">
      <div className="w-full max-w-7xl pb-6">
        <nav
          className="h-full overflow-y-auto border-l border-r border-b border-gray-200 rounded-md"
          aria-label="Student list"
        >
          {Object.keys(groupedStudents).map((schoolName) => (
            <div key={schoolName} className="relative">
              <div className="sticky top-0 border-t border-b border-gray-200 bg-gray-50 px-6 py-3 text-sm font-medium text-gray-700">
                <h3>{schoolName}</h3>
              </div>
              <ul
                role="list"
                className="relative divide-y divide-gray-200 border-x border-gray-200"
              >
                {Object.values(groupedStudents[schoolName].students).map(
                  (student: IStudent) => (
                    <li key={student.id} className="bg-white">
                      <div className="relative flex items-center space-x-3 px-5 py-3 focus-within:ring-2 focus-within:ring-inset focus-within:ring-gray-500 hover:bg-gray-50">
                        <div className="flex-shrink-0">
                          <UserCircleIcon
                            className="h-7 w-7 text-gray-500"
                            strokeWidth={1}
                          />
                        </div>
                        <div className="min-w-0 flex-1 pl-1">
                          <a
                            href={`/students/${student.id}`}
                            className="focus:outline-none z-10"
                          >
                            {/* Extend touch target to entire panel */}
                            <p className="text-sm font-medium text-gray-900">
                              <span
                                className="absolute inset-0"
                                aria-hidden="true"
                              />
                              {student.first_name} {student.last_name}
                            </p>
                          </a>

                          {student.customers?.map((customer) => (
                            <div
                              key={customer.id}
                              className="text-sm text-gray-900"
                            >
                              {customer.first_name} {customer.last_name}{" "}
                              <a
                                className="relative text-sm text-gray-500 z-20 hover:text-gray-600 focus-within:text-gray-700"
                                href={mailtoStr(customer, student.customers)}
                              >
                                {customer.email}
                              </a>
                            </div>
                          ))}
                        </div>
                      </div>
                    </li>
                  )
                )}
              </ul>
            </div>
          ))}
        </nav>
      </div>
    </div>
  );
}

export function StudentsRoute() {
  const [studentList, setStudentList] = useState<StudentData[] | null>(null);

  useEffect(() => {
    fetch("/api/students/list")
      .then((res) => res.json())
      .then((data) => setStudentList(data));
  }, []);

  return <Students studentList={studentList} />;
}
