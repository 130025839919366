import React from "react";

import { GoogleLogin } from "react-google-login";
import { Redirect } from "react-router";
import Logo from "../logo.png";

const LoginForm = () => {
  const [loggedIn, setLoggedIn] = React.useState(false);

  const handleLogin = async (googleData: any) => {
    const res = await fetch("/api/auth/google", {
      method: "POST",
      body: JSON.stringify({
        token: googleData.tokenId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    setLoggedIn(res.status === 201);
  };

  // If the loggedIn state has been set to true, we should try and redirect the
  // user to the homepage
  if (loggedIn) {
    return <Redirect to={"/lessons/today"} />;
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-16 w-auto" src={Logo} alt="Pelican Music" />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Sign in to your account
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div>
            <div>
              <GoogleLogin
                clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
                buttonText="Login with Pelican Email"
                accessType="online"
                onSuccess={handleLogin}
                onFailure={handleLogin}
                cookiePolicy={"single_host_origin"}
                className="w-full flex justify-center rounded-md"
              />
            </div>
          </div>

          <div className="mt-6">
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-gray-500">
                  Having problems?
                </span>
              </div>
            </div>

            <div className="mt-6">
              <div className="flex justify-center">
                <p>
                  Email{" "}
                  <a
                    href="mailto:admin@pelican-music.co.uk"
                    className="text-blue-600"
                  >
                    admin@pelican-music.co.uk
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const LoginRoute = () => {
  return <LoginForm />;
};
