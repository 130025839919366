export type TeacherData = {
  avatar_url: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  lessonInstances?: ILessonInstance[]
};

// TODO: Type me
export type Lesson = any;
export type LessonInstance = any;
export type School = any;
export type Customer = any;
export type Student = any;

export interface IStudent {
  id: number;
  first_name: string;
  last_name: string;
  date_of_birth: Date;
  image_permissions: number;
  notes: string;
  gender: string;
  special_educational_needs_disabilities: Boolean;
  customers?: ICustomer[];
  studentReports?: IStudentReport[];
  lessons?: ILesson[];
}

export interface ICustomer {
  id: number;
  first_name: string;
  last_name: string;
  email?: string;
  phone_number?: number;
}

export interface ITeacher {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
};

export interface ILesson {
  id: number;
  student: IStudent;
  school: ISchool;
  teacher: ITeacher;
  lessonSubject: ILessonSubject;
  lessonInstances?: ILessonInstance[];
  iso_day_of_week: number;
  duration_minutes: number;
  performanceSubmissions: IPerformanceSubmission[];
}

export interface ILessonInstance {
  id: number;
  scheduled_at: Date;
  lessonRegistration?: ILessonRegistration;
  school?: ISchool;
}

export interface ILessonRegistration {
  id: number;
  taught: boolean;
  not_taught_reason: string | null;
  not_taught_reason_desc: string | null;
}

export interface ISchool {
  id: number;
  name: string;
  school_address?: string;
  school_phone?: string;
  head_teacher?: string;
  head_teacher_email?: string;
  senco?: string;
  senco_email?: string;
  safeguarding_lead_name?: string;
  safeguarding_lead_email?: string;
}

export interface ILessonSubject {
  id: number;
  subject: string;
};

export interface ILessonType {
  id: string;
  name: string;
};

export interface IAcademicLesson {
  id: number;
  duration_minutes: number;
  school: ISchool;
  academicLessonInstances?: IAcademicLessonInstance[]
}

export interface IAcademicLessonRegistration {
  id: number;
}

export interface IAcademicLessonInstance {
  id: number;
  academicLesson: IAcademicLesson;
  scheduled_at: Date;
  academicLessonRegistration?: IAcademicLessonRegistration;
  lessonSubject?: ILessonSubject;
  school: ISchool;
}

export interface IInstance {
  lessonID: number;
  isodow: number;
  duration_minutes: number;
  description: string;
  school_name: string;
  lesson_type_id: string;
  lesson_category_id: number;
  lesson_subject_str: string;
  first_scheduled_at: Date;
  todays_registration_taught: boolean | null;
  pending_lesson_registrations: number;
}

export interface ISchoolInstance extends ISchool {
  lesson_ids: Record<number, number>;
  isoDayOfWeekTaught: Record<number, number>;
}

export interface IGroupLesson {
  id: number;
  school: ISchool;
  duration_minutes: number;
  lessonSubject: ILessonSubject;
  groupLessonInstances?: IGroupLessonInstance[]
}

export interface IGroupLessonInstance {
  id: number;
  groupLesson: IGroupLesson;
  scheduled_at: Date;
  groupLessonRegistration: IGroupLessonRegistration;
}

export interface IGroupLessonRegistration {
  id: number;
  taught: boolean;
  groupLessonRegistrationStudents?: IGroupLessonRegistrationStudent[];
}

export interface IGroupLessonRegistrationStudent {
  id: number;
  student: IStudent;
}

export interface IPerformance {
  performance_id: number;
  id: number; // TODO: performance_id -> id
  description: string;
  type: string;
  location?: string;
  school_ids: number[];
  school_names: number[];
  scheduled_at: Date;
  submission_deadline: Date;
  host_name?: string;
  host_email?: string;
  accompanist_name?: string;
  accompanist_email?: string;
}

export interface IPerformanceSubmission {
  id: number;
  repertoire: string;
  accompanist: boolean;
  performance?: IPerformance;
  lesson?: ILesson;
}

export interface IStudentReport {
  id: number;
  student_id: number;
  teacher?: ITeacher;
  achievements: string | null;
  targets: string | null;
  created_at: Date
}

export interface IExam {
  id: number;
  type: ExamType;
  grade: ExamGrade;
  customer_amount: number;
}

export interface RadioOption<T> {
  name: string;
  value: T[keyof T];
  description?: string;
}

export interface SelectOption<T> {
  name: string;
  value: T[keyof T];
  description?: string;
}

export enum LessonCategory {
  Individual = 1,
  Academic,
  Ensemble
}

export enum Sort {
  Before = -1,
  Same = 0,
  After = 1
}

export enum ExamType {
  Theory = 1,
  Practical,
  Performance
}

export enum ExamSeason {
  Winter = 1,
  Spring,
  Summer
}

export enum Accompanist {
  False,
  True
}

export enum ExamGrade {
  Initial,
  One,
  Two,
  Three,
  Four,
  Five,
  Six,
  Seven,
  Eight,
  Prep,
}

export enum Month {
  January = 1,
  February,
  March,
  April,
  May,
  June,
  July,
  August,
  September,
  October,
  November,
  December
}
