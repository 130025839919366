/**
 * Calculate a student's year group from their date of birth
 * 
 * @param {Date} dob The student's date of birth
 * @returns {string} The student's year group
 */

// school terms - SEP-DEC = 1, JAN-MAR = 2, APR-AUG 3
export default function studentYearGroup(dob: Date): string {

  if (!dob || dob.toString() === 'Invalid Date' || typeof dob !== 'object') {
    return 'N/A';
  }

  const birthYear = dob.getFullYear();
  const birthMonth = dob.getMonth();
  const today = new Date();

  const yearGroup = today.getFullYear()
    - birthYear
    - 3                               // nursery start age
    - (birthMonth >= 8 ? 1 : 0)       // year behind if born in term one
    - (today.getMonth() < 8 ? 1 : 0); // adjust for current year if in term 2/3

  if (yearGroup < 0 || yearGroup > 13) return 'N/A';
  if (yearGroup === 0) return 'Nursery';
  if (yearGroup === 1) return 'Reception';

  return (yearGroup - 1).toString();
}
