import _ from "lodash";
import { useEffect, useState } from "react";
import { IInstance } from "../types";
import { useParams } from "react-router-dom";
import { LessonsList } from "./LessonsTodayRoute";
import sortLessonInstances from "../utils/sortLessonInstances";

export const SchoolLessonsRoute = () => {
  const [instancesData, setInstancesData] = useState<IInstance[]>([]);

  let { schoolID, isoDayOfWeek } = useParams<{
    schoolID: string;
    isoDayOfWeek: string;
  }>();

  useEffect(() => {
    fetch(`/api/lessons?school_id=${schoolID}&isodow=${isoDayOfWeek}`)
      .then((res) => res.json())
      .then((data) => setInstancesData(sortLessonInstances(data)));
  }, [schoolID, isoDayOfWeek]);

  if (_.isEmpty(instancesData)) {
    return <div className="flex justify-center">Loading...</div>;
  }

  return (
    <>
      <div className="bg-white py-5  sm:px-6">
        <h3 className="text-xl leading-6 font-bold text-gray-900 mb-2">
          Lessons
        </h3>

        <LessonsList isForToday={false} instances={instancesData} />
      </div>
    </>
  );
};
