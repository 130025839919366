const defaultDateTimeOptions: Intl.DateTimeFormatOptions = {
  dateStyle: "short", timeZone: "UTC"
};

/**
 * Returns a formatted date string, given a valid date string or object and an
 * optional options object.
 *
 * @param {string|Date} date A valid date string or object
 * @returns {string} The formatted date string
 */
export default function formatDate(
  date: string | Date,
  options: Intl.DateTimeFormatOptions = defaultDateTimeOptions
): string {
  const d = new Date(date);
  return d.toString() !== "Invalid Date" ? (
    new Intl.DateTimeFormat("en-GB", options).format(d)
  ) : "";
}
