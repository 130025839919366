import { Navigation } from "../components/Navigation";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { LessonsTodayRoute } from "./LessonsTodayRoute";
import { SchoolsRoute } from "./SchoolsRoute";
import { ProfileRoute } from "./ProfileRoute";
import { SchoolDaysRoute } from "./SchoolDaysRoute";
import { SchoolLessonsRoute } from "./SchoolLessonsRoute";
import { RegisterRoute } from "./RegisterRoute";
import { AcademicRegisterRoute } from "./AcademicRegisterRoute";
import { GroupRegisterRoute } from "./GroupRegisterRoute";
import { HomeRoute } from "./HomeRoute";
import { StudentsRoute } from "./StudentsRoute";
import { StudentProfile } from "./StudentProfile";
import { PerformanceRoute } from "./PerformanceRoute";
import { StudentReportRoute } from "./StudentReportRoute";
import { ExamSubmissionRoute } from "./ExamSubmissionRoute";

export const AuthenticatedAppRoutes = () => {
  return (
    <>
      <Navigation></Navigation>

      <div className="p-3">
        <Switch>
          <Route exact path="/" component={HomeRoute} />
          <Route exact path="/profile" component={ProfileRoute} />
          <Route exact path="/students/reports" component={StudentReportRoute} />
          <Route exact path="/students/:studentID" component={StudentProfile} />
          <Route exact path="/students" component={StudentsRoute} />
          <Route exact path="/performances" component={PerformanceRoute} />
          <Route exact path="/exams" component={ExamSubmissionRoute} />
          <Route exact path="/lessons/today" component={LessonsTodayRoute} />
          <Route
            exact
            path="/schools/:schoolID/:isoDayOfWeek"
            component={SchoolLessonsRoute}
          />
          <Route exact path="/schools/:schoolID" component={SchoolDaysRoute} />
          <Route exact path="/schools" component={SchoolsRoute} />
          <Route exact path="/register/:lessonID" component={RegisterRoute} />
          <Route
            exact
            path="/academic_lesson_register/:academicLessonID"
            component={AcademicRegisterRoute}
          />
          <Route
            exact
            path="/group_lesson_register/:groupLessonID"
            component={GroupRegisterRoute}
          />

          {/* // Catch all */}
          <Route path="/" component={HomeRoute} />
        </Switch>
      </div>
    </>
  );
};
