import { AuthenticatedRoute } from "./components/AuthenticatedRoute";
import { LoginRoute } from "./routes/LoginRoute";
import { LogoutRoute } from "./routes/LogoutRoute";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AuthenticatedAppRoutes } from "./routes/AuthenticatedAppRoutes";

function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route exact path="/login" component={LoginRoute} />
          <Route exact path="/logout" component={LogoutRoute} />
          <AuthenticatedRoute path="/" component={AuthenticatedAppRoutes} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
