import { useEffect, useState } from "react";
import { TeacherData, ISchool } from "../types";
import {
  BanknotesIcon,
  ClockIcon,
  ExclamationTriangleIcon,
  LifebuoyIcon,
} from "@heroicons/react/24/outline";

// At Home Tuition, Online Plus & Online contexts do not have relevant school information
const EXCLUDED_CONTEXTS = [19, 21, 25];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface ActionItem {
  title: string;
  description: string;
  href: string;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  iconForeground: string;
  iconBackground: string;
}

const Profile = ({ teacherData }: { teacherData: TeacherData | null }) => {
  if (!teacherData) {
    // TODO: Loading
    return null;
  }

  const actions: ActionItem[] = [
    {
      title: "Admin query",
      description: "Contact us regarding general queries",
      href: "mailto:admin@pelican-music.co.uk",
      icon: LifebuoyIcon,
      iconForeground: "text-rose-700",
      iconBackground: "bg-rose-50",
    },
    {
      title: "Finance query",
      description: "Contact us regarding invoicing and payments",
      href: "mailto:accounts@pelican-music.co.uk",
      icon: BanknotesIcon,
      iconForeground: "text-yellow-700",
      iconBackground: "bg-yellow-50",
    },
    {
      title: "Report an incident",
      description: "Report an incident of concern",
      href: "https://form.typeform.com/to/o64BQpdC",
      icon: ExclamationTriangleIcon,
      iconForeground: "text-purple-700",
      iconBackground: "bg-purple-50",
    },
    {
      title: "Request time off",
      description: "Request a leave of absence",
      href: "https://form.typeform.com/to/mBmtdNoW",
      icon: ClockIcon,
      iconForeground: "text-teal-700",
      iconBackground: "bg-teal-50",
    },
  ];

  let teacherSchools: ISchool[] = [];
  if (teacherData?.lessonInstances && teacherData.lessonInstances.length) {
    teacherSchools = teacherData.lessonInstances
      .map((li) => li?.school)
      .filter((s): s is ISchool => Boolean(s))
      .filter((s) => !EXCLUDED_CONTEXTS.includes(s.id));
  }

  return (
    <div className="bg-white shadow overflow-hidden rounded-lg">
      {/* https://tailwindui.com/components/application-ui/data-display/description-lists#component-04580360f17ae91c953fda614240075e */}
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Teacher profile
        </h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          Personal details and administration.
        </p>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Full name</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {teacherData.first_name} {teacherData.last_name}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Email address</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {teacherData.email}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Phone number</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {teacherData.phone_number}
            </dd>
          </div>
        </dl>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Pelican teacher number</dt>
          <dd className="mt-1 text-sm text-gray-900">
            <a href={`tel:07521181923`}>
              07521 181923
            </a>
          </dd>
        </div>
      </div>

      {teacherSchools.length > 0 && (
        <ListItem schools={teacherSchools} />
      )}

      {/* https://tailwindui.com/components/application-ui/lists/grid-lists#component-a26a744b444974a4cc73cb5886b8da6a */}
      <div className="bg-gray-200 overflow-hidden divide-y divide-gray-200 border-t border-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px">
        {actions.map((action, actionIdx) => (
          <div
            key={action.title}
            className={classNames(
              actionIdx === actions.length - 2 ? "sm:rounded-bl-lg" : "",
              actionIdx === actions.length - 1
                ? "rounded-bl-lg rounded-br-lg sm:rounded-bl-none"
                : "",
              "relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500"
            )}
          >
            <div>
              <h3 className="text-lg font-medium flex items-center">
                <span
                  className={classNames(
                    action.iconBackground,
                    action.iconForeground,
                    "rounded-lg inline-flex p-3 ring-4 ring-white mr-4"
                  )}
                >
                  <action.icon className="h-6 w-6" aria-hidden="true" />
                </span>
                <a
                  href={action.href}
                  className="focus:outline-none"
                  target={"_blank"}
                >
                  {/* Extend touch target to entire panel */}
                  <span className="absolute inset-0" aria-hidden="true" />
                  {action.title}
                </a>
              </h3>
              <p className="mt-2 text-sm text-gray-500">{action.description}</p>
            </div>
            <span
              className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
              aria-hidden="true"
            >
              <svg
                className="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
              </svg>
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

function ListItem({ schools }: { schools: ISchool[] }) {
  return (
    <>
      <div className="border-t border-gray-200 px-4 py-4 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900 pb-3">
          Your school information
        </h3>
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          {schools.map((school: ISchool) => (
            <div key={school.id} className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-800">{school.name}</dt>
              <dd className="mt-1 text-sm text-gray-900">
                <ul role="list" className="divide-y divide-gray-100">
                  <li className="flex gap-x-4 py-1">
                    <div className="min-w-0">
                      {school?.school_address && (
                        <p className="mt-1 truncate text-xs leading-5 text-gray-500">Address: {school.school_address}</p>
                      )}
                      {school?.school_phone && (
                        <p className="mt-1 truncate text-xs leading-5 text-gray-500">Phone: {school.school_phone}</p>
                      )}
                      {(school?.head_teacher && school?.head_teacher_email) && (
                        <p className="mt-1 truncate text-xs leading-5 text-gray-500">Head teacher:{" "}
                          <a className="text-blue-600" href={`mailto:${encodeURIComponent(school.head_teacher_email)}`}>
                            {school.head_teacher}
                          </a>
                        </p>
                      )}
                      {(school?.senco && school?.senco_email) && (
                        <p className="mt-1 truncate text-xs leading-5 text-gray-500">SENCo:{" "}
                          <a className="text-blue-600" href={`mailto:${encodeURIComponent(school.senco_email)}`}>
                            {school.senco}
                          </a>
                        </p>
                      )}
                      {(school?.safeguarding_lead_name && school?.safeguarding_lead_email) && (
                        <p className="mt-1 truncate text-xs leading-5 text-gray-500">Safeguarding lead:{" "}
                          <a className="text-blue-600" href={`mailto:${encodeURIComponent(school.safeguarding_lead_email)}`}>
                            {school.safeguarding_lead_name}
                          </a>
                        </p>
                      )}
                    </div>
                  </li>
                </ul>
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </>
  )
}

export const ProfileRoute = () => {
  // TODO: Use types from backend
  const [teacherData, setTeacherData] = useState<TeacherData | null>(null);

  useEffect(() => {
    fetch("/api/identity")
      .then((res) => res.json())
      .then((data) => setTeacherData(data.teacher));
  }, []);

  return <Profile teacherData={teacherData} />;
};
