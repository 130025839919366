import { IInstance, LessonCategory, Sort } from "../types";

function isIndividualLesson(instance: IInstance) {
  return instance.lesson_category_id === LessonCategory.Individual;
}

function isAcademicLesson(instance: IInstance) {
  return instance.lesson_category_id === LessonCategory.Academic;
}

function isEnsembleLesson(instance: IInstance) {
  return instance.lesson_category_id === LessonCategory.Ensemble;
}

function sortInstancesByDescription(a: IInstance, b: IInstance) {
  return a.description > b.description ? Sort.After
    : b.description > a.description ? Sort.Before : Sort.Same;
}

function sortInstancesByCategory(a: IInstance, b: IInstance) {
  return isEnsembleLesson(a) && isAcademicLesson(b) ? Sort.After
    : isEnsembleLesson(b) && isAcademicLesson(a) ? Sort.Before : Sort.Same;
}

export default function sortLessonInstances(instances: IInstance[]) {
  return instances
    // sort academic and ensemble instances to end (in that order)
    .sort((a: IInstance, b: IInstance) => {
      if (!isIndividualLesson(a) && !isIndividualLesson(b)) {
        return sortInstancesByCategory(a, b);
      }
      return isIndividualLesson(a) ? Sort.Before : Sort.After;
    })
    // sort individual lessons by name
    .sort((a: IInstance, b: IInstance) => {
      if (isIndividualLesson(a) && isIndividualLesson(b)) {
        return sortInstancesByDescription(a, b);
      }
      return Sort.Same;
    })
    // sort academic lessons by name
    .sort((a: IInstance, b: IInstance) => {
      if (isAcademicLesson(a) && isAcademicLesson(b)) {
        return sortInstancesByDescription(a, b);
      }
      return Sort.Same;
    })
    // sort ensemble lessons by name
    .sort((a: IInstance, b: IInstance) => {
      if (isEnsembleLesson(a) && isEnsembleLesson(b)) {
        return sortInstancesByDescription(a, b);
      }
      return Sort.Same;
    });
}