// withAuth.jsx
import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";

const checkAuthStatus = async (): Promise<{
  shouldRedirect: boolean;
}> => {
  return fetch("/api/identity").then((res) =>
    Promise.resolve({
      shouldRedirect: res.status !== 200,
    })
  );
};

export const AuthenticatedRoute = ({ ...props }) => {
  const [loading, setLoading] = useState(true);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  useEffect(() => {
    checkAuthStatus().then(({ shouldRedirect }) => {
      setShouldRedirect(shouldRedirect);
      setLoading(false);
    });
  });

  if (loading) {
    return null;
  }

  if (shouldRedirect) {
    return <Redirect to="/login" />;
  }

  return <Route {...props} />;
};
