import { Month } from "./types";

export const MILLISECONDS_IN_WEEK = 1000 * 60 * 60 * 24 * 7; // 604,800,000

export type DowItem = {
  name: string;
  shortname: string;
  iso_day_of_week: number;
};

export const daysOfWeek: DowItem[] = [
  {
    name: "Monday",
    shortname: "Mon",
    iso_day_of_week: 1,
  },
  {
    name: "Tuesday",
    shortname: "Tue",
    iso_day_of_week: 2,
  },
  {
    name: "Wednesday",
    shortname: "Wed",
    iso_day_of_week: 3,
  },
  {
    name: "Thursday",
    shortname: "Thu",
    iso_day_of_week: 4,
  },
  {
    name: "Friday",
    shortname: "Fri",
    iso_day_of_week: 5,
  },
  {
    name: "Saturday",
    shortname: "Sat",
    iso_day_of_week: 6,
  },
  {
    name: "Sunday",
    shortname: "Sun",
    iso_day_of_week: 7,
  },
];

export const examMonthOptions = [
  {
    name: "January",
    value: Month.January
  },
  {
    name: "February",
    value: Month.February
  },
  {
    name: "March",
    value: Month.March
  },
  {
    name: "April",
    value: Month.April
  },
  {
    name: "May",
    value: Month.May
  },
  {
    name: "June",
    value: Month.June
  },
  {
    name: "July",
    value: Month.July
  },
  {
    name: "August",
    value: Month.August
  },
  {
    name: "September",
    value: Month.September
  },
  {
    name: "October",
    value: Month.October
  },
  {
    name: "November",
    value: Month.November
  },
  {
    name: "December",
    value: Month.December
  }
];
