import { LessonCategory } from "../types";

/**
 * Get the correct registration route for the provided lesson category
 * 
 * @param {LessonCategory} lessonCategoryID
 * @returns {string} the relevant registration url path or an empty string
 */

export default function getRegistrationRoute(lessonCategoryID: LessonCategory): string {
  switch (lessonCategoryID) {
    case LessonCategory.Individual:
      return "register";
    case LessonCategory.Academic:
      return "academic_lesson_register";
    case LessonCategory.Ensemble:
      return "group_lesson_register";
    default:
      return "";
  }
}
