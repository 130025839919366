import _ from "lodash";
import { useEffect, useState } from "react";
import { ISchoolInstance } from "../types";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { CalendarIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

const dow_shorthand = ["M", "T", "W", "T", "F", "S", "S"];
const Schools = ({ schools }: { schools: ISchoolInstance[] }) => {
  if (_.isEmpty(schools)) {
    return <div>No schools</div>;
  }

  schools = _.sortBy(schools, (school) => school.name);

  return (
    <div className="bg-white shadow overflow-hidden rounded-lg">
      <ul className="divide-y divide-gray-200">
        {schools.map((school) => (
          <li key={school.id}>
            <Link
              to={`/schools/${school.id}`}
              className="block hover:bg-gray-50"
            >
              <div className="flex items-center px-4 py-4 sm:px-6">
                <div className="min-w-0 flex-1 flex items-center">
                  <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                    <div>
                      <p className="text-sm font-medium text-indigo-600 truncate">
                        {school.name}
                      </p>
                      <p className="mt-2 flex items-center text-sm text-gray-500">
                        <CalendarIcon
                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        {dow_shorthand
                          .map((d, i) =>
                            school.lesson_ids[i + 1] > 0 ? (
                              <span className="font-bold text-gray-700">
                                {d}
                              </span>
                            ) : (
                              <span className="text-gray-400">{d}</span>
                            )
                          )
                          .reduce((prev, curr) => (
                            <>
                              {prev}
                              <span className="text-gray-100 px-1.5">/</span>
                              {curr}
                            </>
                          ))}
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <ChevronRightIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export const SchoolsRoute = () => {
  // TODO: Use types from backend
  const [schoolsData, setSchoolsData] = useState<ISchoolInstance[]>([]);

  useEffect(() => {
    fetch("/api/schools")
      .then((res) => res.json())
      .then((data) => setSchoolsData(data));
  }, []);

  if (_.isEmpty(schoolsData)) {
    return <div className="flex justify-center">Loading...</div>;
  }
  return (
    <>
      <div className="bg-white py-5 border-b border-gray-200 sm:px-6">
        <h3 className="text-xl leading-6 font-bold text-gray-900">Schools</h3>
      </div>
      <Schools schools={schoolsData} />
    </>
  );
};
