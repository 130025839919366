import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { daysOfWeek } from "../constants";
import { IStudent, ICustomer, ILesson, ITeacher, IStudentReport } from "../types";
import mailtoStr from "../utils/mailtoStr";
import studentYearGroup from "../utils/studentYearGroup";
import formatDate from "../utils/formatDate";

interface StudentProfileData extends IStudent {
  lessons: ILesson[];
  lessonsWithPerformanceSubmissions: ILesson[];
  studentReports: IStudentReport[];
}

/**
 * Returns a Student profile with the Student's associated customers, active
 * lessons with teacher contact details and their performance history.
 */

function Student({
  student,
  teacher,
}: {
  student: StudentProfileData | null;
  teacher: ITeacher | null;
}) {
  if (!teacher) {
    return null;
  }

  if (!student) {
    return (
      <div className="max-w-xl m-auto">
        <div className="mt-10 text-center sm:mt-10">
          <p className="text-sm text-gray-500">Student profile not found.</p>
        </div>
      </div>
    );
  }

  const customers: ICustomer[] | undefined = student?.customers;
  const dob = new Date(student?.date_of_birth);

  return (
    <>
      <div className="w-full mx-auto flex flex-row justify-center items-center">
        <div className="w-full max-w-7xl lg:px-8">
          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Student Profile
              </h3>
            </div>
            <div className="border-t border-gray-200">
              <dl className="divide-y divide-gray-100">
                <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Student</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {student?.first_name} {student?.last_name}
                  </dd>
                </div>

                {customers && (
                  <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      {customers.length > 1 ? "Clients" : "Client"}
                    </dt>
                    <dd className="divide-y divide-gray-100 sm:mt-0 sm:col-span-2">
                      {customers.map((customer) => (
                        <div
                          key={customer.id}
                          className="py-2 text-sm text-gray-900"
                        >
                          <div>
                            {customer.first_name} {customer.last_name}
                          </div>
                          <div>
                            <a href={mailtoStr(customer, customers)}>
                              {customer?.email}
                            </a>
                          </div>
                          <div>
                            <a href={`tel:${customer?.phone_number}`}>
                              {customer?.phone_number}
                            </a>
                          </div>
                        </div>
                      ))}
                    </dd>
                  </div>
                )}

                {dob.toString() !== "Invalid Date" && (
                  <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Date of birth
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {formatDate(dob)}
                    </dd>
                  </div>
                )}

                {dob.toString() !== "Invalid Date" && (
                  <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Year group
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {studentYearGroup(dob)}
                    </dd>
                  </div>
                )}

                <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Student notes
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {student?.notes}
                  </dd>
                </div>

                <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    SEND
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {student?.special_educational_needs_disabilities}
                  </dd>
                </div>

                {(student?.lessons && student.lessons?.length > 0) && (
                  <ActiveLessonList lessons={student.lessons} teacherID={teacher.id} />
                )}

                {(student?.lessonsWithPerformanceSubmissions && student.lessonsWithPerformanceSubmissions?.length > 0) && (
                  <PerformanceSubmissionList lessonsWithPerformanceSubmissions={student.lessonsWithPerformanceSubmissions} />
                )}

                {(student?.studentReports && student.studentReports?.length > 0) && (
                  <StudentReportList studentReports={student.studentReports} />
                )}

              </dl>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function ActiveLessonList({ lessons, teacherID }: { lessons: ILesson[], teacherID: number }) {
  return (
    <div className="border-t border-gray-200 px-4 py-4 sm:px-6">
      <h3 className="text-m leading-6 font-medium text-gray-900 pb-3">
        Active lessons
      </h3>
      <ul>
        {lessons.map((lesson) => (
          <li
            key={lesson.id}
            className="px-3 py-1 sm:px-3 grid grid-row-2 sm:grid sm:grid-cols-5 text-sm"
          >
            <div className="w-full sm:py-1 sm:pr-2 sm:col-span-4">
              {lesson?.duration_minutes + "m "}
              {lesson?.lessonSubject?.subject + " "}
              at
              {" " + lesson?.school?.name + " "}
              {"(" +
                daysOfWeek[lesson.iso_day_of_week - 1]
                  .shortname +
                ") "}
              {lesson?.teacher?.id !== teacherID
                ? `with ${lesson?.teacher?.first_name} ${lesson?.teacher?.last_name}`
                : ""}
            </div>
            {/* if the lesson's teacher is not the current teacher provide their contact email */}
            {lesson?.teacher?.id !== teacherID && (
              <div className="py-1 sm:py-1 sm:col-span-1 flex flex-row justify-content items-center">
                <a
                  href={mailtoStr(lesson?.teacher)}
                  className="font-medium text-gray-600 hover:text-gray-400"
                >
                  Contact {lesson?.teacher?.first_name}
                </a>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

function PerformanceSubmissionList({ lessonsWithPerformanceSubmissions }: { lessonsWithPerformanceSubmissions: ILesson[] }) {

  const performanceSubmissions = lessonsWithPerformanceSubmissions
    .flatMap((lesson) => {
      const performances = lesson.performanceSubmissions.map((sub) => {
        return {
          ...sub,
          lessonSubject: lesson.lessonSubject.subject
        }
      });
      return performances;
    });

  if (!performanceSubmissions.length) {
    return <></>;
  }

  return (
    <div className="border-t border-gray-200 px-4 py-4 sm:px-6">
      <h3 className="text-m leading-6 font-medium text-gray-900 pb-3">
        Performance history
      </h3>
      <ul>
        {performanceSubmissions.map((performanceSubmission) => (
          <li key={performanceSubmission.id} className="px-3 py-1 sm:px-3 ">
            {performanceSubmission?.performance && (
              <div className="text-sm font-medium text-gray-800">
                {performanceSubmission.performance.type}: {performanceSubmission.performance.description}
              </div>
            )}
            <div className="text-sm text-gray-900">
              {performanceSubmission?.performance?.scheduled_at && (
                <p className="truncate text-xs leading-5 text-gray-500">
                  {formatDate(performanceSubmission.performance.scheduled_at)}
                </p>
              )}
              <div className="min-w-0">
                {performanceSubmission?.lessonSubject && (
                  <p className="truncate text-xs leading-5 text-gray-500">
                    {performanceSubmission.lessonSubject}: {performanceSubmission.repertoire}
                  </p>
                )}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}


function StudentReportList({ studentReports }: { studentReports: IStudentReport[] }) {
  return (
    <div className="border-t border-gray-200 px-4 py-4 sm:px-6">
      <h3 className="text-m leading-6 font-medium text-gray-900 pb-3">
        Student reports
      </h3>
      <ul>
        {studentReports.map((studentReport) => (
          <li key={studentReport.id} className="px-3 py-1 sm:px-3">
            {studentReport?.teacher && (
              <div className="text-sm font-medium text-gray-800 pb-1">
                {studentReport.teacher.first_name} {studentReport.teacher.last_name}
              </div>
            )}
            <div className="text-sm text-gray-900">
              {studentReport?.targets && (
                <p className="text-xs leading-5 text-gray-500 pb-2">
                  Targets: {studentReport.targets}
                </p>
              )}
              {studentReport?.achievements && (
                <p className="text-xs leading-5 text-gray-500 pb-2">
                  Achievements: {studentReport.achievements}
                </p>
              )}
              {studentReport?.targets && (
                <div className="min-w-0">
                  <p className="truncate text-xs leading-5 text-gray-500">
                    {formatDate(studentReport.created_at)}
                  </p>
                </div>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export function StudentProfile() {
  const [student, setStudent] = useState<StudentProfileData | null>(null);
  const [teacherData, setTeacherData] = useState<ITeacher | null>(null);

  const { studentID } = useParams<{ studentID: string }>();

  useEffect(() => {
    fetch("/api/identity")
      .then((res) => res.json())
      .then((data) => setTeacherData(data.teacher))
      .catch((e) => console.error(e));
  }, []);

  useEffect(() => {
    fetch(`/api/students/profile/${studentID}`)
      .then((res) => res.json())
      .then((data) => setStudent(data))
      .catch((e) => console.error(e));
  }, [studentID]);

  return <Student student={student} teacher={teacherData} />;
}
