import _ from "lodash";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { getISODay } from "date-fns";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { daysOfWeek } from "../constants";
import { IInstance } from "../types";
import { MILLISECONDS_IN_WEEK } from "../constants";
import sortLessonInstances from "../utils/sortLessonInstances";
import getRegistrationRoute from "../utils/getRegistrationRoute";
import formatDate from "../utils/formatDate";

function formatDuration(dur: number) {
  return dur > 60
    ? `${Math.floor(dur / 60)}hr ${dur % 60 === 0 ? "" : `${dur % 60}m`}`
    : `${dur}m`;
}

/**
 * Returns a Boolean indicating whether the first instance of the lesson
 * is > two week from now. If a lesson does not start for two weeks, it is
 * disabled, and the start date indicated in the teacher's lesson list.
 *
 * @param {Date} first_scheduled_at The first scheduled instance of the lesson.
 * @returns {boolean}
 */

function activeLesson(first_scheduled_at: Date): boolean {
  const date = new Date(first_scheduled_at);
  if (date.toString() === "Invalid Date") {
    return true; // enable the lesson on err
  }
  return new Date().getTime() + MILLISECONDS_IN_WEEK * 2 > date.getTime();
}

export const LessonsList = ({
  isForToday,
  instances,
}: {
  isForToday: boolean;
  instances: IInstance[] | null;
}) => {
  if (!instances || instances.length === 0) {
    return (
      <div className="max-w-xl m-auto">
        <div className="my-12">
          <div className="mt-3 text-center sm:mt-5">
            <div className="text-lg leading-6 font-medium text-gray-900">
              No lessons {isForToday ? "for today" : ""}
            </div>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                We couldn't find any lessons{" "}
                {isForToday ? "which we were expecting to be taught today" : ""}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-6 flex flex-col space-y-4 sm:flex-row-reverse sm:space-y-0">
          <Link
            type="button"
            className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm flex-1 sm:ml-4"
            to={"/lessons"}
          >
            View all lessons
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white shadow overflow-hidden rounded-lg">
      <ul className="divide-y divide-gray-200">
        {instances.map((instance) => {
          const registrationRoute = getRegistrationRoute(
            instance.lesson_category_id
          );
          // skip the instance if the lesson_category_id is not recognised
          if (!registrationRoute) {
            return;
          }
          return LessonListItem(instance, registrationRoute, isForToday);
        })}
      </ul>
    </div>
  );
};

/**
 * Conditionally wrap children in the provided wrapper
 *
 * @param {boolean} condition Whether to wrap the component
 * @returns The wrapped children or children
 */
function ConditionalWrapper({
  condition,
  wrapper,
  children,
}: {
  condition: boolean;
  wrapper: Function;
  children: React.ReactNode;
}) {
  return condition ? wrapper(children) : children;
}

/**
 * Returns a Link element containing the provided children.
 *
 * @param {string} path The registration route path
 * @returns A Link element containing the provided children
 */
function LessonListItemLink({
  path,
  children,
}: {
  path: string;
  children: React.ReactNode;
}) {
  return (
    <Link to={path} className="block hover:bg-gray-50">
      {children}
    </Link>
  );
}

/**
 * Returns a LessonList item, optionally wrapped in a Link component.
 *
 * In-active lessons are excluded from the 'Today's lessons' page and are
 * displayed with a notification containing their start date on the 'All lessons'
 * page. If the lesson is active and has pending registrations a count of pending
 * registrations is given. If lesson registrations are up to date a 'Registered'
 * notification is given on the Lesson's today page and a 'No pending
 * registrations' notification from the 'All lessons' view.
 *
 * This reflects how teachers use the register system. If they are using the
 * Lesson's today view, they will benefit from knowing they have recorded the
 * lesson for that day. If they are not recording registrations on the day, by
 * using school's page, they need to know all registrations for the current
 * lesson are complete.
 *
 * @param {IInstance} instance The current instance
 * @param {string} registrationRoute The registration path
 * @param {boolean} isForToday Boolean indicating if there is a lesson scheduled today
 * @returns
 */

function LessonListItem(
  instance: IInstance,
  registrationRoute: string,
  isForToday: boolean
) {
  const pendingRegistrations = Number(instance.pending_lesson_registrations);
  if (Number.isNaN(pendingRegistrations)) {
    return;
  }

  // Exclude lessons from the 'Today's lessons' view if they are due to start in future.
  const isActiveLesson = activeLesson(instance.first_scheduled_at);
  if (isForToday && !isActiveLesson) {
    return;
  }

  // Disable the Link if the lesson is not active, or if the teacher is on
  // the Today's lessons view and the lesson has no pending registrations.
  const isEnabled = !isActiveLesson
    ? false
    : pendingRegistrations === 0 && isForToday
      ? false
      : true;

  const path = `/${registrationRoute}/${instance.lessonID}`;

  return (
    <li key={instance.lessonID + instance.lesson_type_id}>
      <ConditionalWrapper
        condition={isEnabled}
        wrapper={(children: React.ReactNode) => (
          <LessonListItemLink path={path} children={children} />
        )}
      >
        <div
          className={`flex items-center px-4 py-4 sm:px-6 ${!isActiveLesson ? "bg-gray-100" : ""
            }`}
        >
          <div className="min-w-0 flex-1 flex items-center">
            <div className="min-w-0 flex-1 flex flex-col justify-center">
              <div className="text-sm font-medium text-blue-600">
                {instance.description}{" "}
                {!isActiveLesson ? (
                  <span className="inline-flex items-center ml-1 px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-gray-800">
                    {instance.first_scheduled_at
                      ? `Start date: ${formatDate(instance.first_scheduled_at)}`
                      : ""}
                  </span>
                ) : pendingRegistrations > 0 ? (
                  <span className="inline-flex items-center ml-1 px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                    {`${instance.pending_lesson_registrations} pending registrations`}
                  </span>
                ) : (
                  <span className="inline-flex items-center ml-1 px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                    {isForToday ? "Registered" : "No pending registrations"}
                  </span>
                )}
              </div>
              <div className="text-sm font-medium text-gray-400">
                {instance.lesson_subject_str} &middot;{" "}
                {formatDuration(instance.duration_minutes)} &middot;{" "}
                {daysOfWeek[instance.isodow - 1].shortname} &middot;{" "}
                {instance.school_name}
              </div>
            </div>
          </div>
          {isEnabled ? (
            <div>
              <ChevronRightIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
          ) : null}
        </div>
      </ConditionalWrapper>
    </li>
  );
}

export const LessonsTodayRoute = () => {
  const [instancesData, setInstancesData] = useState<IInstance[] | null>([]);
  const today = new Date();
  useEffect(() => {
    fetch(`/api/lessons?isodow=${getISODay(today)}`)
      .then((res) => res.json())
      .then((instances) => setInstancesData(sortLessonInstances(instances)));
  }, [getISODay(today)]);

  return <LessonsList isForToday={true} instances={instancesData} />;
};
