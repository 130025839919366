import _ from "lodash";
import { useEffect, useState } from "react";
import { ISchoolInstance } from "../types";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { Link, useParams } from "react-router-dom";
import { daysOfWeek } from "../constants";

const DaysTable = ({
  schoolID,
  instances,
}: {
  schoolID: string;
  instances: ISchoolInstance;
}) => {
  if (_.isEmpty(instances)) {
    return <div>loading</div>;
  }

  return (
    <div className="bg-white shadow overflow-hidden rounded-lg">
      <ul className="divide-y divide-gray-200">
        {daysOfWeek.map((dow) => (
          <li key={dow.iso_day_of_week}>
            {instances.lesson_ids[dow.iso_day_of_week] > 0 ? (
              <Link
                to={`/schools/${schoolID}/${dow.iso_day_of_week}`}
                className="block hover:bg-gray-50"
              >
                <div className="flex items-center px-4 py-4 sm:px-6">
                  <div className="min-w-0 flex-1 flex items-center">
                    <div className="min-w-0 flex-1">
                      <div className="text-sm font-medium text-blue-600">
                        <span className="font-bold">{dow.name}</span> (
                        {instances.lesson_ids[dow.iso_day_of_week]} lessons)
                      </div>
                    </div>
                  </div>
                  <div>
                    <ChevronRightIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                </div>
              </Link>
            ) : (
              <div className="block bg-gray-200">
                <div className="flex items-center px-4 py-4 sm:px-6">
                  <div className="min-w-0 flex-1 flex items-center">
                    <div className="min-w-0 flex-1">
                      <div className="text-sm font-normal text-gray-500">
                        {dow.name} (no lessons)
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export const SchoolDaysRoute = () => {
  const [instancesData, setInstancesData] = useState<ISchoolInstance>();

  let { schoolID } = useParams<{ schoolID: string }>();

  useEffect(() => {
    fetch(`/api/schools/${schoolID}`)
      .then((res) => res.json())
      .then((data: ISchoolInstance[]) => setInstancesData(data[0]));
  }, [schoolID]);

  if (_.isEmpty(instancesData) || !instancesData) {
    return <div className="flex justify-center">Loading...</div>;
  }

  return (
    <>
      <div className="bg-white py-5 border-b border-gray-200 sm:px-6">
        <h3 className="text-xl leading-6 font-bold text-gray-900 mb-3">
          {instancesData.name}
        </h3>
        <DaysTable schoolID={schoolID} instances={instancesData} />
      </div>
    </>
  );
};
